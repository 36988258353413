var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Primeiro Nome","label-for":"v-firstName"}},[_c('b-form-input',{attrs:{"id":"v-firstName","state":errors.length > 0 ? false : null,"placeholder":"descrição","disabled":(!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') &&
                    !!_vm.userPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_USER_INSERIR') && !_vm.userPlace.id)},model:{value:(_vm.userPlace.firstName),callback:function ($$v) {_vm.$set(_vm.userPlace, "firstName", $$v)},expression:"userPlace.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ultimo Nome","label-for":"v-lastName"}},[_c('b-form-input',{attrs:{"id":"v-lastName","state":errors.length > 0 ? false : null,"placeholder":"descrição","disabled":(!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') &&
                    !!_vm.userPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_USER_INSERIR') && !_vm.userPlace.id)},model:{value:(_vm.userPlace.lastName),callback:function ($$v) {_vm.$set(_vm.userPlace, "lastName", $$v)},expression:"userPlace.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nome Completo","label-for":"v-nomeCompleto"}},[_c('b-form-input',{attrs:{"id":"v-nomeCompleto","placeholder":"nome Completo","disabled":(!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') &&
                  !!_vm.userPlace.id) ||
                (!_vm.verificarPermissao('ROLE_USER_INSERIR') && !_vm.userPlace.id)},model:{value:(_vm.userPlace.nomeCompleto),callback:function ($$v) {_vm.$set(_vm.userPlace, "nomeCompleto", $$v)},expression:"userPlace.nomeCompleto"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"v-email"}},[_c('b-form-input',{attrs:{"id":"v-email","type":"email","placeholder":"email","state":errors.length > 0 ? false : null,"disabled":(!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') &&
                    !!_vm.userPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_USER_INSERIR') && !_vm.userPlace.id)},model:{value:(_vm.userPlace.email),callback:function ($$v) {_vm.$set(_vm.userPlace, "email", $$v)},expression:"userPlace.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Perfil","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perfil","label-for":"v-role"}},[_c('v-select',{attrs:{"reduce":function (comboPerfils) { return comboPerfils.id; },"label":"nome","options":_vm.comboPerfils,"state":errors.length > 0 ? false : null,"disabled":(!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') &&
                    !!_vm.userPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_USER_INSERIR') && !_vm.userPlace.id)},model:{value:(_vm.userPlace.perfil),callback:function ($$v) {_vm.$set(_vm.userPlace, "perfil", $$v)},expression:"userPlace.perfil"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.userPlace.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"assinatura","label-for":"v-assinatura"}},[_c('b-form-file',{attrs:{"placeholder":"Selecione um arquivo...","drop-placeholder":"Drop file here...","browse-text":"Procurar"},model:{value:(_vm.assinatura),callback:function ($$v) {_vm.assinatura=$$v},expression:"assinatura"}})],1)],1):_vm._e(),(_vm.userPlace.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.userPlace.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') &&
                  !!_vm.userPlace.id) ||
                (!_vm.verificarPermissao('ROLE_USER_INSERIR') && !_vm.userPlace.id)},model:{value:(_vm.userPlace.ativo),callback:function ($$v) {_vm.$set(_vm.userPlace, "ativo", $$v)},expression:"userPlace.ativo"}},[_vm._v(" "+_vm._s(_vm.userPlace.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_USER_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_USER_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }