<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="firstName"
              rules="required"
            >
              <b-form-group label="Primeiro Nome" label-for="v-firstName">
                <b-form-input
                  id="v-firstName"
                  v-model="userPlace.firstName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descrição"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') &&
                      !!userPlace.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !userPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="lastName"
              rules="required"
            >
              <b-form-group label="Ultimo Nome" label-for="v-lastName">
                <b-form-input
                  id="v-lastName"
                  v-model="userPlace.lastName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descrição"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') &&
                      !!userPlace.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !userPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Nome Completo" label-for="v-nomeCompleto">
              <b-form-input
                id="v-nomeCompleto"
                v-model="userPlace.nomeCompleto"
                placeholder="nome Completo"
                :disabled="
                  (!verificarPermissao('ROLE_USER_ATUALIZAR') &&
                    !!userPlace.id) ||
                  (!verificarPermissao('ROLE_USER_INSERIR') && !userPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-group label="Email" label-for="v-email">
                <b-form-input
                  id="v-email"
                  v-model="userPlace.email"
                  type="email"
                  placeholder="email"
                  :state="errors.length > 0 ? false : null"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') &&
                      !!userPlace.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !userPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Perfil"
              rules="required"
            >
              <b-form-group label="Perfil" label-for="v-role">
                <v-select
                  v-model="userPlace.perfil"
                  :reduce="(comboPerfils) => comboPerfils.id"
                  label="nome"
                  :options="comboPerfils"
                  :state="errors.length > 0 ? false : null"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') &&
                      !!userPlace.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !userPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col v-if="userPlace.id" cols="12">
            <b-form-group label="assinatura" label-for="v-assinatura">
              <b-form-file
                v-model="assinatura"
                placeholder="Selecione um arquivo..."
                drop-placeholder="Drop file here..."
                browse-text="Procurar"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="userPlace.id" cols="12">
            <b-form-group>
              <b-form-checkbox
                v-model="userPlace.ativo"
                :checked="userPlace.ativo"
                name="check-button"
                switch
                inline
                :disabled="
                  (!verificarPermissao('ROLE_USER_ATUALIZAR') &&
                    !!userPlace.id) ||
                  (!verificarPermissao('ROLE_USER_INSERIR') && !userPlace.id)
                "
              >
                {{ userPlace.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_USER_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_USER_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('usersSettingAccessModuleModule', {
      user: (state) => state.user
    }),
    ...mapState('combosModule', {
      comboPerfils: (state) => state.comboPerfils
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    ButtonsFormVue,
    BFormFile
  },
  directives: {
    Ripple
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Usuarios',
      required,
      userPlace: {
        firstName: '',
        lastName: '',
        nomeCompleto: '',
        email: '',
        password: '',
        perfil: [
          {
            id: ''
          }
        ]
      },
      assinatura: null
    }
  },
  mounted() {
    this.userPlace = JSON.parse(JSON.stringify(this.user))
    this.$store.dispatch('combosModule/loadComboPerfils')
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('usersSettingAccessModuleModule/resetUser')
    },
    insertAssinatura() {},
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.userPlace.id) {
            let data = {
              id: this.userPlace.id,
              assinatura: this.assinatura
            }
            this.$store
              .dispatch('usersSettingAccessModuleModule/insertAssinatura', data)
              .then((res) => {
                if (res.error) {
                  this.MensagemError('Falha ao Editar', response.message)
                  return
                }
                this.$store
                  .dispatch(
                    'usersSettingAccessModuleModule/editUser',
                    this.userPlace
                  )
                  .then((response) => {
                    if (response.response) {
                      this.MensagemError(
                        'Falha ao Editar',
                        response.response.data.error
                      )
                      return
                    }
                    this.showMessageSuccess('Editar', 'Editado com sucesso!')
                    this.$refs.form.reset()
                    this.cancel()
                  })
              })
          } else {
            this.$store
              .dispatch(
                'usersSettingAccessModuleModule/insertNewUser',
                this.userPlace
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.userPlace = {
        firstName: '',
        lastName: '',
        nomeCompleto: '',
        email: '',
        password: '',
        perfil: [
          {
            id: ''
          }
        ]
      }
    }
  }
}
</script>
